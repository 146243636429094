import { extendTheme } from "@chakra-ui/react"

import { Button } from "./components/Button"
import { Card } from "./components/Card"
import { CardBody } from "./components/CardBody"
import { CardHeader } from "./components/CardHeader"
import { Input } from "./components/Input"
import { MainPanel } from "./components/MainPanel"
import { PanelContainer } from "./components/PanelContainer"
import { PanelContent } from "./components/PanelContent"
import { Select } from "./components/Select"
import { Textarea } from "./components/Textarea"
import { customPalette } from "./custom-colors"
import { globalStyles } from "./styles"

export const theme = extendTheme({
  globalStyles,
  config: {
    useSystemColorMode: false,
    initialColorMode: "light",
  },
  fonts: {
    body: "Poppins, sans-serif",
    heading: "Poppins, serif",
  },
  colors: {
    primary: {
      50: "#f2e9ff",
      100: "#d8bff2",
      200: "#bd94e6",
      300: "#a268d9",
      400: "#8a3bcc",
      500: "#7021b3",
      600: "#581989",
      700: "#401160",
      800: "#290937",
      900: "#12000f",
    },
    secondary: {
      50: "#e6e6e6",
      100: "#cccccc",
      200: "#b3b3b3",
      300: "#999999",
      400: "#808080",
      500: "#666666",
      600: "#4d4d4d",
      700: "#333333",
      800: "#1a1a1a",
      900: "#000000",
    },
    accent: {
      50: "#fff2f2",
      100: "#ffbdbd",
      200: "#ff8a8a",
      300: "#ff5757",
      400: "#ff2424",
      500: "#e60b0b",
      600: "#b30909",
      700: "#800707",
      800: "#4d0404",
      900: "#1a0101",
    },
    customPalette,
  },
  components: {
    Button,
    Input,
    Select,
    Textarea,
    MainPanel,
    PanelContainer,
    PanelContent,
    Card,
    CardBody,
    CardHeader,
  },
})
