import { customPalette } from "../custom-colors"

export const Button = {
  baseStyle: {
    borderRadius: 2,
    fontWeight: "bold",
  },
  sizes: {
    md: {
      h: 10,
      minW: 10,
      fontSize: "md",
      px: 4,
    },
  },
  variants: {
    primary: {
      bg: customPalette.mediumPurple,
      color: customPalette.white,
      _hover: {
        bg: customPalette.richPurple,
      },
    },
    secondary: {
      bg: customPalette.richPurple,
      color: customPalette.white,
      _hover: {
        bg: customPalette.deepPurple,
      },
    },
    alert: {
      bg: customPalette.deepPurple,
      color: customPalette.white,
      _hover: {
        bg: customPalette.darkPurple,
      },
    },
  },
}
