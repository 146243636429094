
import type { ReactNode} from 'react';
import React, { createContext, useCallback,useContext, useMemo } from 'react';

import useLocalStorage from 'lib/hooks/useLocalStorage';


interface UserDataContextProps {
    getData: (key: string) => any;
    setData: (key: string, value: any) => void;
    clearData: (key: string) => void;
}

const UserDataContext = createContext<UserDataContextProps | undefined>(undefined);

export const UserDataProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [storedData, setStoredData] = useLocalStorage('userData', {});

    const getData = useCallback((key: string) => {
        return storedData[key];
    }, [storedData]);

    const setData = useCallback((key: string, value: any) => {
        setStoredData((prev: any) => ({ ...prev, [key]: value }));
    }, [setStoredData]);

    const clearData = useCallback((key: string) => {
        setStoredData((prev: any) => {
            const newData = { ...prev };
            delete newData[key];
            return newData;
        });
    }, [setStoredData]);

    const contextValue = useMemo(() => ({ getData, setData, clearData }), [getData, setData, clearData]);

    return (
        <UserDataContext.Provider value={contextValue}>
            {children}
        </UserDataContext.Provider>
    );
};

export const useUserData = () => {
    const context = useContext(UserDataContext);
    if (!context) {
        throw new Error('useUserData must be used within a UserDataProvider');
    }
    return context;
};
