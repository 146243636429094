import * as React from "react";
import { ApolloProvider } from "@apollo/client";
import { ChakraProvider } from "@chakra-ui/react";
import { UserDataProvider } from "context/user-data-context";
import Head from "next/head";
import { NextIntlClientProvider } from 'next-intl';

import { useApollo } from "lib/apollo/client";
import { theme } from "lib/theme";
import { Analytics } from "@vercel/analytics/react"

export default function App(props: any) {
  const { Component, pageProps } = props;
  const apolloClient = useApollo(pageProps.initialApolloState);
  const getLayout = Component.getLayout ?? ((page: any) => page);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>

      <NextIntlClientProvider
        locale="pt-BR"
        timeZone="America/Sao_Paulo"
        messages={pageProps.messages}
      >
        <ChakraProvider theme={theme}>
          <ApolloProvider client={apolloClient}>
            <UserDataProvider>
              {getLayout(<Component {...pageProps} />)}
              <Analytics />
            </UserDataProvider>
          </ApolloProvider>
        </ChakraProvider>
      </NextIntlClientProvider>
    </>
  );
}
